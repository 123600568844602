.font-all {
    font-family: 'Raleway', sans-serif;
    font-weight: 400px;
 }
 .h-fact{
    height:100vh;
 }
 
 .iframe{
    height:577.1px;
    width:1032px;
 }
 *{
     scroll-behavior: smooth;
 }
 .bg-glass{
    background-color: rgb(0, 0, 0, 0.15);
 }
 .bg-glass-dark{
   background-color: rgb(0, 0, 0, 0.10);
}
 .bg-card{
    background-color: #EAEAEA;
 }
 .mission {
    background-color: #E8E4FE;
 }
 .vision {
    background-color: #FFE2DF;
 }
 .strength {
    background-color: #D2E6FC;
 }
 .h-page {
    min-height:75vh;
 }
 .h-scale {
    height: 0vh;
 }
 .font-ball {
    font-family: 'Raleway', sans-serif;
    font-weight: 700px;
 }
 .font-basker {
   /* font-family: 'Baskervville', serif; */
   font-family: 'Hina Mincho', serif;
 }

.font-oxy {
   font-family: 'Oxygen', sans-serif;
}
.pattern{
   /* background-size: cover; */
   width: 100%;
   height: 100%;
   position: relative;
}
.pattern::before {
   content: "";
   position: absolute;
   z-index: -1;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   background: url('./assets/images/bg.png');
   opacity: 1;
   /* filter: blur(1px); */
  /* -webkit-filter: blur(1px); */

}
#myVideo {
   width:100%;
}

.ant-carousel .slick-dots li button {
   background: grey;
   /* height: 10px;
   width: 10px;
   border-radius: 100%; */
}

.ant-carousel .slick-dots li.slick-active button {
   background: red;
}

.stripe {

   --stripe: #034764;
   --bg: #00253b;
 
   background: linear-gradient(135deg, var(--bg) 25%, transparent 25%) -50px 0,
     linear-gradient(225deg, var(--bg) 25%, transparent 25%) -50px 0,
     linear-gradient(315deg, var(--bg) 25%, transparent 25%),
     linear-gradient(45deg, var(--bg) 25%, transparent 25%);
   background-size: 100px 100px;
   background-color: var(--stripe);
  opacity: 0.1;

  /* background-image: url('./assets/images/webicons07.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%; */
  /* margin-bottom: auto;
  margin-left: auto; */

}
.bg-all{
   background-image: url('./assets/images/cloud-banner.jpeg');
   background-position: center;
   background-repeat: no-repeat;
   background-size: cover;
   width: 100vw;
   height:500px; 
}

.object {
   height: 4rem;
   width: 4rem;
   border-radius: 100%;
   background-color: #00253b;
}
 
 
.h-height {
   min-height: 80vh;
}
/* .maps {
   height: 650px;
   width: 600px;
} */
/* The Overlay (background) */
.overlay {
   /* Height & width depends on how you want to reveal the overlay (see JS below) */   
   height: 0%;
   width: 100%;
   position: fixed; /* Stay in place */
   z-index: 1; /* Sit on top */
   left: 0;
   top: 0;
   background-color: #00253b; 
   overflow-x: hidden; /* Disable horizontal scroll */
   transition: 0.5s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
 }
 
 /* Position the content inside the overlay */
 .overlay-content {
   position: relative;
   top: 25%; 
   width: 100%;
   text-align: center;
   margin-top: 30px; /* 30px top margin to avoid conflict with the close button on smaller screens */
 }
 
 /* .overlay a {
   padding: 8px;
   text-decoration: none;
   font-size: 36px;
   color: #818181;
   display: block;
   transition: 0.3s; 
 } */
 
 /* When you mouse over the navigation links, change their color */
 /* .overlay a:hover, .overlay a:focus {
   color: #f1f1f1;
 } */
 
 /* Position the close button (top right corner) */
 .overlay .close-btn {
   position: absolute;
   top: 20px;
   right: 45px;
   font-size: 60px;
 }
 
/* On screens that are 600px wide or less, make the columns stack on top of each other instead of next to each other */
@media screen and (min-height: 1000px) {
   .h-fact {
      min-height: 1250px;
      background-color: aqua;
      
   }
   .iframe {
      height: 350px;
      width: 90vw;
    }
}

@media (min-height: 600px) and  (max-width: 600px) {
   .h-height {
       height: 100vh;
   }
   /* .maps {
      width:100%;
      height: 20em;
   } */
   .overlay .close-btn {
     font-size: 40px;
     top: 15px;
     right: 35px;
   } 

   .iframe {
   height: 250px;
   width: 90vw;
 }
}