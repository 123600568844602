since nested groupes are not supported we have to use 
     regular css for the nested dropdowns 
  */
  li>ul                 { transform: translatex(100%) scale(0) }
  li:hover>ul           { transform: translatex(101%) scale(1) }
  li > button svg       { transform: rotate(-90deg) }
  li:hover > button svg { transform: rotate(-270deg) }

  /* Below styles fake what can be achieved with the tailwind config
     you need to add the group-hover variant to scale and define your custom
     min width style.
  	 See https://codesandbox.io/s/tailwindcss-multilevel-dropdown-y91j7?file=/index.html
  	 for implementation with config file
  */
  .group:hover .group-hover\:scale-100 { transform: scale(1) }
  .group:hover .group-hover\:-rotate-180 { transform: rotate(180deg) }
  .scale-0 { transform: scale(0) }
  .min-w-32 { min-width: 8rem }
  
  .nav-br:hover {
      position: relative;
      padding: 0.3rem;
      /* border: 1px solid red;	 */
  }
  .nav-br:after {
   display: block;
   content: "";
   width: 20px;
   height: 20px;
   position: absolute;
   top: -5px;
   right: -5px;
   border-top: 1px solid #F2651A;
   border-right: 1px solid #F2651A; 
  }
  .nav-br:before {
   display: block;
   content: "";
   width: 20px;
   height: 20px;
   position: absolute;
   bottom: -5px;
   left: -5px;
   border-bottom: 1px solid #F2651A;
   border-left: 1px solid #F2651A;
}
.text-stuff {
   color: #ccc;
}
.text-stuff:hover {
   color: #F2651A;
}
.text-stuff:focus {
   color:#F2651A;
}

  